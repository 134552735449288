/*
vars
*/
/* current price */
.page-coin-title {
  margin: 0;
  clear: none;
  float: left;
  border-bottom: 0 !important;
  width: 50% !important;
}

.table a, .table a:link, .table a:visited, .table a:hover {
  color: #000;
  text-decoration: none;
}

#tradingimg {
  width: 6em;
  height: 6em;
}

#tradingPair {
  font-size: 120%;
}

#currentCoinPrice {
  font-size: 180%;
  font-weight: 400;
}

.fa-blank {
  visibility: hidden;
}

/* markets */
.market-coin {
  white-space: nowrap;
}

.market-price, .market-volume {
  white-space: nowrap;
  cursor: pointer;
}

#price-indicators {
  color: #fff;
}

#marketBlock {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover td a, .table-hover tbody tr:hover th {
  background-color: #c0c0c0;
  color: #fff;
  font-weight: 500;
}

table[data-sortable] th, table[data-sortable] td {
  padding: 0.3rem !important;
}

/* transformations */
.price {
  transition: color 0.25s ease-in-out;
}

.price-up {
  color: #28a745;
}

.price-down {
  color: #dc3545;
}

/* charts */
#chartcontainer, #tv_chart_container {
  height: 480px;
  margin-right: 10px;
}

.labelColor {
  background-color: #000;
}

.candleColor {
  background-color: #f00;
}

.candleUpColor {
  background-color: #fff;
}

.candleLineColor {
  background-color: #f00;
}

.candleUpLineColor {
  background-color: green;
}

/* forms */
.input-group-prepend {
  color: #000;
}

.input-group-prepend img {
  width: 18px;
}

.trade-group input {
  text-align: right;
  color: #000;
}

.fee {
  font-size: 80%;
  color: #888;
}

#trade-message {
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  margin: 0;
}

.btnhighlight {
  background-color: #6c757d;
  color: #fff;
}

/* trade blocks */
.table {
  margin-bottom: 0;
}

.table-striped th, #coin_market_table th {
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 1px 1px 0 0 #dee2e6;
  -moz-box-shadow: 1px 1px 0 0 #dee2e6;
  -webkit-box-shadow: 1px 1px 0 0 #dee2e6;
}

#coin_market_table {
  overflow: scroll;
}

.coinListTicker {
  margin-right: -9px;
  width: 18px;
  height: 18px;
}

.coinListPair {
  width: 22px;
  height: 22px;
}

.coinListPair2 {
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.addFav {
  font-size: 80%;
}

.tabs {
  cursor: pointer;
}

#sell-orders, #buy-orders, #open-sell-orders, #open-buy-orders {
  max-height: 275px;
  overflow-y: scroll;
}

#buySlide, #sellSlide, .slide, .tradeSlider {
  cursor: pointer;
}

#buySlide div, #sellSlide div, .slide div, .tradeSlider div {
  position: absolute;
  top: -5px;
  border: 2px solid #fff;
  background-color: #e0e0e0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.ui-slider {
  height: 6px;
  background-color: #e0e0e0 !important;
}

.ui-slider-handle {
  top: -7px !important;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.ui-slider-handle:focus {
  outline: none;
}

.filter {
  cursor: pointer;
}

.favCol {
  width: 35px;
}

/* trade history */
#trade-history {
  max-height: 555px;
  overflow-y: hidden;
}

/*
mobile
*/
@media (max-width: 720px) {
  /* menu */
  #mobilemenu {
    background-color: #48535D;
    height: 32px;
  }

  #mobilemenu ul li i, #mobilemenu ul li a {
    color: #fff;
  }

  #showMarkets, #showCharts {
    cursor: pointer;
    padding: 10px 5px;
    margin-bottom: -30px;
  }

  /* markets */
  #tradingimg {
    width: 5em;
    height: 5em;
  }

  #currentCoinPrice {
    font-size: 140%;
  }

  #price-indicators {
    font-size: 80%;
  }

  .market-price, .market-volume {
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .market-price {
    max-width: 90px;
  }

  /* charts */
  .page-coin-title {
    border-bottom: 1px solid #c0c0c0 !important;
    width: 100% !important;
  }

  #chartcontainer, #tv_chart_container {
    margin-right: 0;
    margin-bottom: 45px;
  }

  /* trade blocks */
  #buy, #sell {
    clear: both;
    margin: 0 20px;
  }

  .confirm-label {
    font-size: 90%;
  }
}