.rads-tab, .rads-active-tab {
  padding: 5px 5px 10px 5px !important;
  text-align: center;
  margin-top: -10px;
  width: auto;
}

.rads-active-tab {
  padding: 6px 5px 2px 5px !important;
}

.nav-tabs {
  float: left;
  text-align: left;
}

.withdrawalInfo {
  font-weight: 700;
  cursor: pointer;
}

.withdrawalInfoBody {
  word-wrap: break-word;
  display: inline-block;
}

#depositOptions .col-12, #withdrawOptions .col-12, #profile .col-12 {
  padding-left: 0;
  padding-right: 0;
}

#depositOptions .card .card-header, #withdrawOptions .card .card-header, #profile .card .card-header {
  background-color: #fff;
  font-weight: 700;
}

.depositgroup, .depositfiat, .depositcrypto, .depositmetals, .withdrawgroup, .withdrawfiat, .withdrawcrypto, .withdrawmetals {
  cursor: pointer;
  overflow-y: scroll;
}

.depositgroup div:hover, .depositfiat div:hover, .depositcrypto div:hover, .depositmetals div:hover, .withdrawgroup div:hover, .withdrawfiat div:hover, .withdrawcrypto div:hover, .withdrawmetals div:hover {
  color: #000;
  background-color: #f2f2f2;
}

.depositgroup .selected:hover, .depositfiat .selected:hover, .depositcrypto .selected:hover, .depositmetals .selected:hover, .withdrawgroup .selected:hover, .withdrawfiat .selected:hover, .withdrawcrypto .selected:hover, .withdrawmetals .selected:hover {
  color: #000;
}

.depositgroup .selected, .depositfiat .selected, .depositcrypto .selected, .depositmetals .selected, .withdrawgroup .selected, .withdrawfiat .selected, .withdrawcrypto .selected, .withdrawmetals .selected {
  color: #000;
  background-color: #d9d9d9;
  border-color: #000 !important;
}

.depositgroup div img, .depositfiat div img, .depositcrypto div img, .depositmetals div img, .withdrawgroup div img, .withdrawfiat div img, .withdrawcrypto div img, .withdrawmetals div img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

#listcrypto {
  background-image: linear-gradient(#fff, #fafafa);
}

#profileNav {
  cursor: pointer;
  font-size: 120%;
}

#profileNav ul li:hover, .profileNavHighlight {
  background-color: #f8f9fa;
}

.amount-src {
  cursor: pointer;
  font-weight: 700;
}

#walletSuccess {
  font-weight: bold;
  font-size: 150%;
}

#walletMessage a {
  color: #000;
  text-decoration: underline;
}

.hide-me[aria-expanded=true] {
  display: none;
}

.fa-edit, .fa-eye-slash, .fa-eye {
  cursor: pointer;
}

/*
savings
*/
#savingsrates tbody, #savings tbody {
  line-height: 30px;
}

#savingsrates tbody img, #savings tbody img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

/*
mobile
*/
@media (max-width: 575.98px) {
  .row {
    margin: 0;
  }

  .page-title {
    margin: 0;
  }
}