/* ticker/symbol, color, dark color */
/* build classes */
/* generic */
.act-background {
  background-color: #6c757d;
  color: #fff;
}

.act-background-dark {
  background-color: #343a40;
}

.act-border, .act-border-promo {
  border: 1px solid #6c757d;
  box-shadow: 0 0 0 0;
}

.act-border-dark {
  border: 1px solid #343a40;
}

.act-chart-volume {
  background-color: #343a40;
}

/* navigation */
.nav-pills > li > a.act,
.nav-pills > li > a:hover .act,
.nav-pills > li > a:focus .act {
  color: #6c757d;
}

.nav-pills .nav-link.act.active,
.nav-pills .nav-link:hover.act.active,
.nav-pills .nav-link:focus.act.active {
  color: #fff;
  background-color: #6c757d;
}

.act-text-color {
  color: #6c757d;
}

/* generic */
.btc-background {
  background-color: #FF9900;
  color: #fff;
}

.btc-background-dark {
  background-color: #E87900;
}

.btc-border, .btc-border-promo {
  border: 1px solid #FF9900;
  box-shadow: 0 0 0 0;
}

.btc-border-dark {
  border: 1px solid #E87900;
}

.btc-chart-volume {
  background-color: #E87900;
}

/* navigation */
.nav-pills > li > a.btc,
.nav-pills > li > a:hover .btc,
.nav-pills > li > a:focus .btc {
  color: #FF9900;
}

.nav-pills .nav-link.btc.active,
.nav-pills .nav-link:hover.btc.active,
.nav-pills .nav-link:focus.btc.active {
  color: #fff;
  background-color: #FF9900;
}

.btc-text-color {
  color: #FF9900;
}

/* generic */
.eth-background {
  background-color: #6a89a5;
  color: #fff;
}

.eth-background-dark {
  background-color: #477192;
}

.eth-border, .eth-border-promo {
  border: 1px solid #6a89a5;
  box-shadow: 0 0 0 0;
}

.eth-border-dark {
  border: 1px solid #477192;
}

.eth-chart-volume {
  background-color: #477192;
}

/* navigation */
.nav-pills > li > a.eth,
.nav-pills > li > a:hover .eth,
.nav-pills > li > a:focus .eth {
  color: #6a89a5;
}

.nav-pills .nav-link.eth.active,
.nav-pills .nav-link:hover.eth.active,
.nav-pills .nav-link:focus.eth.active {
  color: #fff;
  background-color: #6a89a5;
}

.eth-text-color {
  color: #6a89a5;
}

/* generic */
.bcc-background {
  background-color: #85bb65;
  color: #fff;
}

.bcc-background-dark {
  background-color: #6d9a53;
}

.bcc-border, .bcc-border-promo {
  border: 1px solid #85bb65;
  box-shadow: 0 0 0 0;
}

.bcc-border-dark {
  border: 1px solid #6d9a53;
}

.bcc-chart-volume {
  background-color: #6d9a53;
}

/* navigation */
.nav-pills > li > a.bcc,
.nav-pills > li > a:hover .bcc,
.nav-pills > li > a:focus .bcc {
  color: #85bb65;
}

.nav-pills .nav-link.bcc.active,
.nav-pills .nav-link:hover.bcc.active,
.nav-pills .nav-link:focus.bcc.active {
  color: #fff;
  background-color: #85bb65;
}

.bcc-text-color {
  color: #85bb65;
}

/* generic */
.dash-background {
  background-color: #1c75bc;
  color: #fff;
}

.dash-background-dark {
  background-color: #165b92;
}

.dash-border, .dash-border-promo {
  border: 1px solid #1c75bc;
  box-shadow: 0 0 0 0;
}

.dash-border-dark {
  border: 1px solid #165b92;
}

.dash-chart-volume {
  background-color: #165b92;
}

/* navigation */
.nav-pills > li > a.dash,
.nav-pills > li > a:hover .dash,
.nav-pills > li > a:focus .dash {
  color: #1c75bc;
}

.nav-pills .nav-link.dash.active,
.nav-pills .nav-link:hover.dash.active,
.nav-pills .nav-link:focus.dash.active {
  color: #fff;
  background-color: #1c75bc;
}

.dash-text-color {
  color: #1c75bc;
}

/* generic */
.bsv-background {
  background-color: #e9b200;
  color: #fff;
}

.bsv-background-dark {
  background-color: #ca9a00;
}

.bsv-border, .bsv-border-promo {
  border: 1px solid #e9b200;
  box-shadow: 0 0 0 0;
}

.bsv-border-dark {
  border: 1px solid #ca9a00;
}

.bsv-chart-volume {
  background-color: #ca9a00;
}

/* navigation */
.nav-pills > li > a.bsv,
.nav-pills > li > a:hover .bsv,
.nav-pills > li > a:focus .bsv {
  color: #e9b200;
}

.nav-pills .nav-link.bsv.active,
.nav-pills .nav-link:hover.bsv.active,
.nav-pills .nav-link:focus.bsv.active {
  color: #fff;
  background-color: #e9b200;
}

.bsv-text-color {
  color: #e9b200;
}

/* generic */
.zec-background {
  background-color: #CB842A;
  color: #fff;
}

.zec-background-dark {
  background-color: #b67635;
}

.zec-border, .zec-border-promo {
  border: 1px solid #CB842A;
  box-shadow: 0 0 0 0;
}

.zec-border-dark {
  border: 1px solid #b67635;
}

.zec-chart-volume {
  background-color: #b67635;
}

/* navigation */
.nav-pills > li > a.zec,
.nav-pills > li > a:hover .zec,
.nav-pills > li > a:focus .zec {
  color: #CB842A;
}

.nav-pills .nav-link.zec.active,
.nav-pills .nav-link:hover.zec.active,
.nav-pills .nav-link:focus.zec.active {
  color: #fff;
  background-color: #CB842A;
}

.zec-text-color {
  color: #CB842A;
}

/* generic */
.xmr-background {
  background-color: #ff7519;
  color: #fff;
}

.xmr-background-dark {
  background-color: #c75e18;
}

.xmr-border, .xmr-border-promo {
  border: 1px solid #ff7519;
  box-shadow: 0 0 0 0;
}

.xmr-border-dark {
  border: 1px solid #c75e18;
}

.xmr-chart-volume {
  background-color: #c75e18;
}

/* navigation */
.nav-pills > li > a.xmr,
.nav-pills > li > a:hover .xmr,
.nav-pills > li > a:focus .xmr {
  color: #ff7519;
}

.nav-pills .nav-link.xmr.active,
.nav-pills .nav-link:hover.xmr.active,
.nav-pills .nav-link:focus.xmr.active {
  color: #fff;
  background-color: #ff7519;
}

.xmr-text-color {
  color: #ff7519;
}

/* generic */
.ltc-background {
  background-color: #345d9d;
  color: #fff;
}

.ltc-background-dark {
  background-color: #2a4d84;
}

.ltc-border, .ltc-border-promo {
  border: 1px solid #345d9d;
  box-shadow: 0 0 0 0;
}

.ltc-border-dark {
  border: 1px solid #2a4d84;
}

.ltc-chart-volume {
  background-color: #2a4d84;
}

/* navigation */
.nav-pills > li > a.ltc,
.nav-pills > li > a:hover .ltc,
.nav-pills > li > a:focus .ltc {
  color: #345d9d;
}

.nav-pills .nav-link.ltc.active,
.nav-pills .nav-link:hover.ltc.active,
.nav-pills .nav-link:focus.ltc.active {
  color: #fff;
  background-color: #345d9d;
}

.ltc-text-color {
  color: #345d9d;
}

/* generic */
.btg-background {
  background-color: #db9a1e;
  color: #fff;
}

.btg-background-dark {
  background-color: #b37e19;
}

.btg-border, .btg-border-promo {
  border: 1px solid #db9a1e;
  box-shadow: 0 0 0 0;
}

.btg-border-dark {
  border: 1px solid #b37e19;
}

.btg-chart-volume {
  background-color: #b37e19;
}

/* navigation */
.nav-pills > li > a.btg,
.nav-pills > li > a:hover .btg,
.nav-pills > li > a:focus .btg {
  color: #db9a1e;
}

.nav-pills .nav-link.btg.active,
.nav-pills .nav-link:hover.btg.active,
.nav-pills .nav-link:focus.btg.active {
  color: #fff;
  background-color: #db9a1e;
}

.btg-text-color {
  color: #db9a1e;
}

/* generic */
.neo-background {
  background-color: #00ae1d;
  color: #fff;
}

.neo-background-dark {
  background-color: #018a18;
}

.neo-border, .neo-border-promo {
  border: 1px solid #00ae1d;
  box-shadow: 0 0 0 0;
}

.neo-border-dark {
  border: 1px solid #018a18;
}

.neo-chart-volume {
  background-color: #018a18;
}

/* navigation */
.nav-pills > li > a.neo,
.nav-pills > li > a:hover .neo,
.nav-pills > li > a:focus .neo {
  color: #00ae1d;
}

.nav-pills .nav-link.neo.active,
.nav-pills .nav-link:hover.neo.active,
.nav-pills .nav-link:focus.neo.active {
  color: #fff;
  background-color: #00ae1d;
}

.neo-text-color {
  color: #00ae1d;
}

/* generic */
.gas-background {
  background-color: #00ae1d;
  color: #fff;
}

.gas-background-dark {
  background-color: #018a18;
}

.gas-border, .gas-border-promo {
  border: 1px solid #00ae1d;
  box-shadow: 0 0 0 0;
}

.gas-border-dark {
  border: 1px solid #018a18;
}

.gas-chart-volume {
  background-color: #018a18;
}

/* navigation */
.nav-pills > li > a.gas,
.nav-pills > li > a:hover .gas,
.nav-pills > li > a:focus .gas {
  color: #00ae1d;
}

.nav-pills .nav-link.gas.active,
.nav-pills .nav-link:hover.gas.active,
.nav-pills .nav-link:focus.gas.active {
  color: #fff;
  background-color: #00ae1d;
}

.gas-text-color {
  color: #00ae1d;
}

/* generic */
.btcp-background {
  background-color: #272d63;
  color: #fff;
}

.btcp-background-dark {
  background-color: #1a1e44;
}

.btcp-border, .btcp-border-promo {
  border: 1px solid #272d63;
  box-shadow: 0 0 0 0;
}

.btcp-border-dark {
  border: 1px solid #1a1e44;
}

.btcp-chart-volume {
  background-color: #1a1e44;
}

/* navigation */
.nav-pills > li > a.btcp,
.nav-pills > li > a:hover .btcp,
.nav-pills > li > a:focus .btcp {
  color: #272d63;
}

.nav-pills .nav-link.btcp.active,
.nav-pills .nav-link:hover.btcp.active,
.nav-pills .nav-link:focus.btcp.active {
  color: #fff;
  background-color: #272d63;
}

.btcp-text-color {
  color: #272d63;
}

/* generic */
.usdt-background {
  background-color: #22a079;
  color: #fff;
}

.usdt-background-dark {
  background-color: #176d53;
}

.usdt-border, .usdt-border-promo {
  border: 1px solid #22a079;
  box-shadow: 0 0 0 0;
}

.usdt-border-dark {
  border: 1px solid #176d53;
}

.usdt-chart-volume {
  background-color: #176d53;
}

/* navigation */
.nav-pills > li > a.usdt,
.nav-pills > li > a:hover .usdt,
.nav-pills > li > a:focus .usdt {
  color: #22a079;
}

.nav-pills .nav-link.usdt.active,
.nav-pills .nav-link:hover.usdt.active,
.nav-pills .nav-link:focus.usdt.active {
  color: #fff;
  background-color: #22a079;
}

.usdt-text-color {
  color: #22a079;
}

/* generic */
.nmc-background {
  background-color: #27A9E3;
  color: #fff;
}

.nmc-background-dark {
  background-color: #27A9E3;
}

.nmc-border, .nmc-border-promo {
  border: 1px solid #27A9E3;
  box-shadow: 0 0 0 0;
}

.nmc-border-dark {
  border: 1px solid #27A9E3;
}

.nmc-chart-volume {
  background-color: #27A9E3;
}

/* navigation */
.nav-pills > li > a.nmc,
.nav-pills > li > a:hover .nmc,
.nav-pills > li > a:focus .nmc {
  color: #27A9E3;
}

.nav-pills .nav-link.nmc.active,
.nav-pills .nav-link:hover.nmc.active,
.nav-pills .nav-link:focus.nmc.active {
  color: #fff;
  background-color: #27A9E3;
}

.nmc-text-color {
  color: #27A9E3;
}

/* generic */
.xrp-background {
  background-color: #0073AB;
  color: #fff;
}

.xrp-background-dark {
  background-color: #005880;
}

.xrp-border, .xrp-border-promo {
  border: 1px solid #0073AB;
  box-shadow: 0 0 0 0;
}

.xrp-border-dark {
  border: 1px solid #005880;
}

.xrp-chart-volume {
  background-color: #005880;
}

/* navigation */
.nav-pills > li > a.xrp,
.nav-pills > li > a:hover .xrp,
.nav-pills > li > a:focus .xrp {
  color: #0073AB;
}

.nav-pills .nav-link.xrp.active,
.nav-pills .nav-link:hover.xrp.active,
.nav-pills .nav-link:focus.xrp.active {
  color: #fff;
  background-color: #0073AB;
}

.xrp-text-color {
  color: #0073AB;
}

/* generic */
.xlm-background {
  background-color: #08b5e5;
  color: #fff;
}

.xlm-background-dark {
  background-color: #068eb4;
}

.xlm-border, .xlm-border-promo {
  border: 1px solid #08b5e5;
  box-shadow: 0 0 0 0;
}

.xlm-border-dark {
  border: 1px solid #068eb4;
}

.xlm-chart-volume {
  background-color: #068eb4;
}

/* navigation */
.nav-pills > li > a.xlm,
.nav-pills > li > a:hover .xlm,
.nav-pills > li > a:focus .xlm {
  color: #08b5e5;
}

.nav-pills .nav-link.xlm.active,
.nav-pills .nav-link:hover.xlm.active,
.nav-pills .nav-link:focus.xlm.active {
  color: #fff;
  background-color: #08b5e5;
}

.xlm-text-color {
  color: #08b5e5;
}

/* generic */
.ada-background {
  background-color: #33adad;
  color: #fff;
}

.ada-background-dark {
  background-color: #408888;
}

.ada-border, .ada-border-promo {
  border: 1px solid #33adad;
  box-shadow: 0 0 0 0;
}

.ada-border-dark {
  border: 1px solid #408888;
}

.ada-chart-volume {
  background-color: #408888;
}

/* navigation */
.nav-pills > li > a.ada,
.nav-pills > li > a:hover .ada,
.nav-pills > li > a:focus .ada {
  color: #33adad;
}

.nav-pills .nav-link.ada.active,
.nav-pills .nav-link:hover.ada.active,
.nav-pills .nav-link:focus.ada.active {
  color: #fff;
  background-color: #33adad;
}

.ada-text-color {
  color: #33adad;
}

/* generic */
.trx-background {
  background-color: #780000;
  color: #fff;
}

.trx-background-dark {
  background-color: #380000;
}

.trx-border, .trx-border-promo {
  border: 1px solid #780000;
  box-shadow: 0 0 0 0;
}

.trx-border-dark {
  border: 1px solid #380000;
}

.trx-chart-volume {
  background-color: #380000;
}

/* navigation */
.nav-pills > li > a.trx,
.nav-pills > li > a:hover .trx,
.nav-pills > li > a:focus .trx {
  color: #780000;
}

.nav-pills .nav-link.trx.active,
.nav-pills .nav-link:hover.trx.active,
.nav-pills .nav-link:focus.trx.active {
  color: #fff;
  background-color: #780000;
}

.trx-text-color {
  color: #780000;
}

/* generic */
.doge-background {
  background-color: #ba9f33;
  color: #fff;
}

.doge-background-dark {
  background-color: #8e7927;
}

.doge-border, .doge-border-promo {
  border: 1px solid #ba9f33;
  box-shadow: 0 0 0 0;
}

.doge-border-dark {
  border: 1px solid #8e7927;
}

.doge-chart-volume {
  background-color: #8e7927;
}

/* navigation */
.nav-pills > li > a.doge,
.nav-pills > li > a:hover .doge,
.nav-pills > li > a:focus .doge {
  color: #ba9f33;
}

.nav-pills .nav-link.doge.active,
.nav-pills .nav-link:hover.doge.active,
.nav-pills .nav-link:focus.doge.active {
  color: #fff;
  background-color: #ba9f33;
}

.doge-text-color {
  color: #ba9f33;
}

/* generic */
.btt-background {
  background-color: #c53460;
  color: #fff;
}

.btt-background-dark {
  background-color: #871e53;
}

.btt-border, .btt-border-promo {
  border: 1px solid #c53460;
  box-shadow: 0 0 0 0;
}

.btt-border-dark {
  border: 1px solid #871e53;
}

.btt-chart-volume {
  background-color: #871e53;
}

/* navigation */
.nav-pills > li > a.btt,
.nav-pills > li > a:hover .btt,
.nav-pills > li > a:focus .btt {
  color: #c53460;
}

.nav-pills .nav-link.btt.active,
.nav-pills .nav-link:hover.btt.active,
.nav-pills .nav-link:focus.btt.active {
  color: #fff;
  background-color: #c53460;
}

.btt-text-color {
  color: #c53460;
}

/* generic */
.xau-background {
  background-color: #db9a1e;
  color: #fff;
}

.xau-background-dark {
  background-color: #b37e19;
}

.xau-border, .xau-border-promo {
  border: 1px solid #db9a1e;
  box-shadow: 0 0 0 0;
}

.xau-border-dark {
  border: 1px solid #b37e19;
}

.xau-chart-volume {
  background-color: #b37e19;
}

/* navigation */
.nav-pills > li > a.xau,
.nav-pills > li > a:hover .xau,
.nav-pills > li > a:focus .xau {
  color: #db9a1e;
}

.nav-pills .nav-link.xau.active,
.nav-pills .nav-link:hover.xau.active,
.nav-pills .nav-link:focus.xau.active {
  color: #fff;
  background-color: #db9a1e;
}

.xau-text-color {
  color: #db9a1e;
}

/* generic */
.xag-background {
  background-color: #C0C0C0;
  color: #fff;
}

.xag-background-dark {
  background-color: #A9A9A9;
}

.xag-border, .xag-border-promo {
  border: 1px solid #C0C0C0;
  box-shadow: 0 0 0 0;
}

.xag-border-dark {
  border: 1px solid #A9A9A9;
}

.xag-chart-volume {
  background-color: #A9A9A9;
}

/* navigation */
.nav-pills > li > a.xag,
.nav-pills > li > a:hover .xag,
.nav-pills > li > a:focus .xag {
  color: #C0C0C0;
}

.nav-pills .nav-link.xag.active,
.nav-pills .nav-link:hover.xag.active,
.nav-pills .nav-link:focus.xag.active {
  color: #fff;
  background-color: #C0C0C0;
}

.xag-text-color {
  color: #C0C0C0;
}

/* generic */
.bat-background {
  background-color: #870065;
  color: #fff;
}

.bat-background-dark {
  background-color: #420089;
}

.bat-border, .bat-border-promo {
  border: 1px solid #870065;
  box-shadow: 0 0 0 0;
}

.bat-border-dark {
  border: 1px solid #420089;
}

.bat-chart-volume {
  background-color: #420089;
}

/* navigation */
.nav-pills > li > a.bat,
.nav-pills > li > a:hover .bat,
.nav-pills > li > a:focus .bat {
  color: #870065;
}

.nav-pills .nav-link.bat.active,
.nav-pills .nav-link:hover.bat.active,
.nav-pills .nav-link:focus.bat.active {
  color: #fff;
  background-color: #870065;
}

.bat-text-color {
  color: #870065;
}

/* generic */
.comp-background {
  background-color: #00D395;
  color: #fff;
}

.comp-background-dark {
  background-color: #00694a;
}

.comp-border, .comp-border-promo {
  border: 1px solid #00D395;
  box-shadow: 0 0 0 0;
}

.comp-border-dark {
  border: 1px solid #00694a;
}

.comp-chart-volume {
  background-color: #00694a;
}

/* navigation */
.nav-pills > li > a.comp,
.nav-pills > li > a:hover .comp,
.nav-pills > li > a:focus .comp {
  color: #00D395;
}

.nav-pills .nav-link.comp.active,
.nav-pills .nav-link:hover.comp.active,
.nav-pills .nav-link:focus.comp.active {
  color: #fff;
  background-color: #00D395;
}

.comp-text-color {
  color: #00D395;
}

/* generic */
.dai-background {
  background-color: #f8b249;
  color: #fff;
}

.dai-background-dark {
  background-color: #c68e3a;
}

.dai-border, .dai-border-promo {
  border: 1px solid #f8b249;
  box-shadow: 0 0 0 0;
}

.dai-border-dark {
  border: 1px solid #c68e3a;
}

.dai-chart-volume {
  background-color: #c68e3a;
}

/* navigation */
.nav-pills > li > a.dai,
.nav-pills > li > a:hover .dai,
.nav-pills > li > a:focus .dai {
  color: #f8b249;
}

.nav-pills .nav-link.dai.active,
.nav-pills .nav-link:hover.dai.active,
.nav-pills .nav-link:focus.dai.active {
  color: #fff;
  background-color: #f8b249;
}

.dai-text-color {
  color: #f8b249;
}

/* generic */
.mkr-background {
  background-color: #1aab9b;
  color: #fff;
}

.mkr-background-dark {
  background-color: #0d554d;
}

.mkr-border, .mkr-border-promo {
  border: 1px solid #1aab9b;
  box-shadow: 0 0 0 0;
}

.mkr-border-dark {
  border: 1px solid #0d554d;
}

.mkr-chart-volume {
  background-color: #0d554d;
}

/* navigation */
.nav-pills > li > a.mkr,
.nav-pills > li > a:hover .mkr,
.nav-pills > li > a:focus .mkr {
  color: #1aab9b;
}

.nav-pills .nav-link.mkr.active,
.nav-pills .nav-link:hover.mkr.active,
.nav-pills .nav-link:focus.mkr.active {
  color: #fff;
  background-color: #1aab9b;
}

.mkr-text-color {
  color: #1aab9b;
}

/* generic */
.wbtc-background {
  background-color: #f09242;
  color: #fff;
}

.wbtc-background-dark {
  background-color: #272037;
}

.wbtc-border, .wbtc-border-promo {
  border: 1px solid #f09242;
  box-shadow: 0 0 0 0;
}

.wbtc-border-dark {
  border: 1px solid #272037;
}

.wbtc-chart-volume {
  background-color: #272037;
}

/* navigation */
.nav-pills > li > a.wbtc,
.nav-pills > li > a:hover .wbtc,
.nav-pills > li > a:focus .wbtc {
  color: #f09242;
}

.nav-pills .nav-link.wbtc.active,
.nav-pills .nav-link:hover.wbtc.active,
.nav-pills .nav-link:focus.wbtc.active {
  color: #fff;
  background-color: #f09242;
}

.wbtc-text-color {
  color: #f09242;
}

/* generic */
.link-background {
  background-color: #2a5ada;
  color: #fff;
}

.link-background-dark {
  background-color: #234ab2;
}

.link-border, .link-border-promo {
  border: 1px solid #2a5ada;
  box-shadow: 0 0 0 0;
}

.link-border-dark {
  border: 1px solid #234ab2;
}

.link-chart-volume {
  background-color: #234ab2;
}

/* navigation */
.nav-pills > li > a.link,
.nav-pills > li > a:hover .link,
.nav-pills > li > a:focus .link {
  color: #2a5ada;
}

.nav-pills .nav-link.link.active,
.nav-pills .nav-link:hover.link.active,
.nav-pills .nav-link:focus.link.active {
  color: #fff;
  background-color: #2a5ada;
}

.link-text-color {
  color: #2a5ada;
}

/* generic */
.xtz-background {
  background-color: #2c7df7;
  color: #fff;
}

.xtz-background-dark {
  background-color: #123262;
}

.xtz-border, .xtz-border-promo {
  border: 1px solid #2c7df7;
  box-shadow: 0 0 0 0;
}

.xtz-border-dark {
  border: 1px solid #123262;
}

.xtz-chart-volume {
  background-color: #123262;
}

/* navigation */
.nav-pills > li > a.xtz,
.nav-pills > li > a:hover .xtz,
.nav-pills > li > a:focus .xtz {
  color: #2c7df7;
}

.nav-pills .nav-link.xtz.active,
.nav-pills .nav-link:hover.xtz.active,
.nav-pills .nav-link:focus.xtz.active {
  color: #fff;
  background-color: #2c7df7;
}

.xtz-text-color {
  color: #2c7df7;
}

/* generic */
.dot-background {
  background-color: #e6007a;
  color: #fff;
}

.dot-background-dark {
  background-color: #c40061;
}

.dot-border, .dot-border-promo {
  border: 1px solid #e6007a;
  box-shadow: 0 0 0 0;
}

.dot-border-dark {
  border: 1px solid #c40061;
}

.dot-chart-volume {
  background-color: #c40061;
}

/* navigation */
.nav-pills > li > a.dot,
.nav-pills > li > a:hover .dot,
.nav-pills > li > a:focus .dot {
  color: #e6007a;
}

.nav-pills .nav-link.dot.active,
.nav-pills .nav-link:hover.dot.active,
.nav-pills .nav-link:focus.dot.active {
  color: #fff;
  background-color: #e6007a;
}

.dot-text-color {
  color: #e6007a;
}

/* generic */
.bnb-background {
  background-color: #f3ba2f;
  color: #fff;
}

.bnb-background-dark {
  background-color: #daa72a;
}

.bnb-border, .bnb-border-promo {
  border: 1px solid #f3ba2f;
  box-shadow: 0 0 0 0;
}

.bnb-border-dark {
  border: 1px solid #daa72a;
}

.bnb-chart-volume {
  background-color: #daa72a;
}

/* navigation */
.nav-pills > li > a.bnb,
.nav-pills > li > a:hover .bnb,
.nav-pills > li > a:focus .bnb {
  color: #f3ba2f;
}

.nav-pills .nav-link.bnb.active,
.nav-pills .nav-link:hover.bnb.active,
.nav-pills .nav-link:focus.bnb.active {
  color: #fff;
  background-color: #f3ba2f;
}

.bnb-text-color {
  color: #f3ba2f;
}

/* generic */
.shib-background {
  background-color: #ff0f1f;
  color: #fff;
}

.shib-background-dark {
  background-color: #cc0c18;
}

.shib-border, .shib-border-promo {
  border: 1px solid #ff0f1f;
  box-shadow: 0 0 0 0;
}

.shib-border-dark {
  border: 1px solid #cc0c18;
}

.shib-chart-volume {
  background-color: #cc0c18;
}

/* navigation */
.nav-pills > li > a.shib,
.nav-pills > li > a:hover .shib,
.nav-pills > li > a:focus .shib {
  color: #ff0f1f;
}

.nav-pills .nav-link.shib.active,
.nav-pills .nav-link:hover.shib.active,
.nav-pills .nav-link:focus.shib.active {
  color: #fff;
  background-color: #ff0f1f;
}

.shib-text-color {
  color: #ff0f1f;
}

/* generic */
.matic-background {
  background-color: #8247e5;
  color: #fff;
}

.matic-background-dark {
  background-color: #7533e2;
}

.matic-border, .matic-border-promo {
  border: 1px solid #8247e5;
  box-shadow: 0 0 0 0;
}

.matic-border-dark {
  border: 1px solid #7533e2;
}

.matic-chart-volume {
  background-color: #7533e2;
}

/* navigation */
.nav-pills > li > a.matic,
.nav-pills > li > a:hover .matic,
.nav-pills > li > a:focus .matic {
  color: #8247e5;
}

.nav-pills .nav-link.matic.active,
.nav-pills .nav-link:hover.matic.active,
.nav-pills .nav-link:focus.matic.active {
  color: #fff;
  background-color: #8247e5;
}

.matic-text-color {
  color: #8247e5;
}

/* generic */
.sgb-background {
  background-color: #b35160;
  color: #fff;
}

.sgb-background-dark {
  background-color: #8F404C;
}

.sgb-border, .sgb-border-promo {
  border: 1px solid #b35160;
  box-shadow: 0 0 0 0;
}

.sgb-border-dark {
  border: 1px solid #8F404C;
}

.sgb-chart-volume {
  background-color: #8F404C;
}

/* navigation */
.nav-pills > li > a.sgb,
.nav-pills > li > a:hover .sgb,
.nav-pills > li > a:focus .sgb {
  color: #b35160;
}

.nav-pills .nav-link.sgb.active,
.nav-pills .nav-link:hover.sgb.active,
.nav-pills .nav-link:focus.sgb.active {
  color: #fff;
  background-color: #b35160;
}

.sgb-text-color {
  color: #b35160;
}

/* generic */
.sol-background {
  background-color: #1dd79b;
  color: #fff;
}

.sol-background-dark {
  background-color: #ab66ff;
}

.sol-border, .sol-border-promo {
  border: 1px solid #1dd79b;
  box-shadow: 0 0 0 0;
}

.sol-border-dark {
  border: 1px solid #ab66ff;
}

.sol-chart-volume {
  background-color: #ab66ff;
}

/* navigation */
.nav-pills > li > a.sol,
.nav-pills > li > a:hover .sol,
.nav-pills > li > a:focus .sol {
  color: #1dd79b;
}

.nav-pills .nav-link.sol.active,
.nav-pills .nav-link:hover.sol.active,
.nav-pills .nav-link:focus.sol.active {
  color: #fff;
  background-color: #1dd79b;
}

.sol-text-color {
  color: #1dd79b;
}

/* generic */
.usdc-background {
  background-color: #2775ca;
  color: #fff;
}

.usdc-background-dark {
  background-color: #2775ca;
}

.usdc-border, .usdc-border-promo {
  border: 1px solid #2775ca;
  box-shadow: 0 0 0 0;
}

.usdc-border-dark {
  border: 1px solid #2775ca;
}

.usdc-chart-volume {
  background-color: #2775ca;
}

/* navigation */
.nav-pills > li > a.usdc,
.nav-pills > li > a:hover .usdc,
.nav-pills > li > a:focus .usdc {
  color: #2775ca;
}

.nav-pills .nav-link.usdc.active,
.nav-pills .nav-link:hover.usdc.active,
.nav-pills .nav-link:focus.usdc.active {
  color: #fff;
  background-color: #2775ca;
}

.usdc-text-color {
  color: #2775ca;
}

/* generic */
.ethw-background {
  background-color: #3956de;
  color: #fff;
}

.ethw-background-dark {
  background-color: #657be5;
}

.ethw-border, .ethw-border-promo {
  border: 1px solid #3956de;
  box-shadow: 0 0 0 0;
}

.ethw-border-dark {
  border: 1px solid #657be5;
}

.ethw-chart-volume {
  background-color: #657be5;
}

/* navigation */
.nav-pills > li > a.ethw,
.nav-pills > li > a:hover .ethw,
.nav-pills > li > a:focus .ethw {
  color: #3956de;
}

.nav-pills .nav-link.ethw.active,
.nav-pills .nav-link:hover.ethw.active,
.nav-pills .nav-link:focus.ethw.active {
  color: #fff;
  background-color: #3956de;
}

.ethw-text-color {
  color: #3956de;
}

/* generic */
.flr-background {
  background-color: #e62058;
  color: #fff;
}

.flr-background-dark {
  background-color: #cf1c4f;
}

.flr-border, .flr-border-promo {
  border: 1px solid #e62058;
  box-shadow: 0 0 0 0;
}

.flr-border-dark {
  border: 1px solid #cf1c4f;
}

.flr-chart-volume {
  background-color: #cf1c4f;
}

/* navigation */
.nav-pills > li > a.flr,
.nav-pills > li > a:hover .flr,
.nav-pills > li > a:focus .flr {
  color: #e62058;
}

.nav-pills .nav-link.flr.active,
.nav-pills .nav-link:hover.flr.active,
.nav-pills .nav-link:focus.flr.active {
  color: #fff;
  background-color: #e62058;
}

.flr-text-color {
  color: #e62058;
}

/* generic */
.wld-background {
  background-color: #558ce7;
  color: #fff;
}

.wld-background-dark {
  background-color: #0063b2;
}

.wld-border, .wld-border-promo {
  border: 1px solid #558ce7;
  box-shadow: 0 0 0 0;
}

.wld-border-dark {
  border: 1px solid #0063b2;
}

.wld-chart-volume {
  background-color: #0063b2;
}

/* navigation */
.nav-pills > li > a.wld,
.nav-pills > li > a:hover .wld,
.nav-pills > li > a:focus .wld {
  color: #558ce7;
}

.nav-pills .nav-link.wld.active,
.nav-pills .nav-link:hover.wld.active,
.nav-pills .nav-link:focus.wld.active {
  color: #fff;
  background-color: #558ce7;
}

.wld-text-color {
  color: #558ce7;
}

/* generic */
.atom-background {
  background-color: #9236ac;
  color: #fff;
}

.atom-background-dark {
  background-color: #af497a;
}

.atom-border, .atom-border-promo {
  border: 1px solid #9236ac;
  box-shadow: 0 0 0 0;
}

.atom-border-dark {
  border: 1px solid #af497a;
}

.atom-chart-volume {
  background-color: #af497a;
}

/* navigation */
.nav-pills > li > a.atom,
.nav-pills > li > a:hover .atom,
.nav-pills > li > a:focus .atom {
  color: #9236ac;
}

.nav-pills .nav-link.atom.active,
.nav-pills .nav-link:hover.atom.active,
.nav-pills .nav-link:focus.atom.active {
  color: #fff;
  background-color: #9236ac;
}

.atom-text-color {
  color: #9236ac;
}

/* generic */
.kas-background {
  background-color: #8ec5b9;
  color: #fff;
}

.kas-background-dark {
  background-color: #000d59;
}

.kas-border, .kas-border-promo {
  border: 1px solid #8ec5b9;
  box-shadow: 0 0 0 0;
}

.kas-border-dark {
  border: 1px solid #000d59;
}

.kas-chart-volume {
  background-color: #000d59;
}

/* navigation */
.nav-pills > li > a.kas,
.nav-pills > li > a:hover .kas,
.nav-pills > li > a:focus .kas {
  color: #8ec5b9;
}

.nav-pills .nav-link.kas.active,
.nav-pills .nav-link:hover.kas.active,
.nav-pills .nav-link:focus.kas.active {
  color: #fff;
  background-color: #8ec5b9;
}

.kas-text-color {
  color: #8ec5b9;
}

/* generic */
.pepe-background {
  background-color: #689448;
  color: #fff;
}

.pepe-background-dark {
  background-color: #3f6823;
}

.pepe-border, .pepe-border-promo {
  border: 1px solid #689448;
  box-shadow: 0 0 0 0;
}

.pepe-border-dark {
  border: 1px solid #3f6823;
}

.pepe-chart-volume {
  background-color: #3f6823;
}

/* navigation */
.nav-pills > li > a.pepe,
.nav-pills > li > a:hover .pepe,
.nav-pills > li > a:focus .pepe {
  color: #689448;
}

.nav-pills .nav-link.pepe.active,
.nav-pills .nav-link:hover.pepe.active,
.nav-pills .nav-link:focus.pepe.active {
  color: #fff;
  background-color: #689448;
}

.pepe-text-color {
  color: #689448;
}

/* generic */
.sui-background {
  background-color: #4da2ff;
  color: #fff;
}

.sui-background-dark {
  background-color: #4693e9;
}

.sui-border, .sui-border-promo {
  border: 1px solid #4da2ff;
  box-shadow: 0 0 0 0;
}

.sui-border-dark {
  border: 1px solid #4693e9;
}

.sui-chart-volume {
  background-color: #4693e9;
}

/* navigation */
.nav-pills > li > a.sui,
.nav-pills > li > a:hover .sui,
.nav-pills > li > a:focus .sui {
  color: #4da2ff;
}

.nav-pills .nav-link.sui.active,
.nav-pills .nav-link:hover.sui.active,
.nav-pills .nav-link:focus.sui.active {
  color: #fff;
  background-color: #4da2ff;
}

.sui-text-color {
  color: #4da2ff;
}

/* generic */
.avax-background {
  background-color: #e84142;
  color: #fff;
}

.avax-background-dark {
  background-color: #ff394a;
}

.avax-border, .avax-border-promo {
  border: 1px solid #e84142;
  box-shadow: 0 0 0 0;
}

.avax-border-dark {
  border: 1px solid #ff394a;
}

.avax-chart-volume {
  background-color: #ff394a;
}

/* navigation */
.nav-pills > li > a.avax,
.nav-pills > li > a:hover .avax,
.nav-pills > li > a:focus .avax {
  color: #e84142;
}

.nav-pills .nav-link.avax.active,
.nav-pills .nav-link:hover.avax.active,
.nav-pills .nav-link:focus.avax.active {
  color: #fff;
  background-color: #e84142;
}

.avax-text-color {
  color: #e84142;
}