/* admin */
.reportsTable {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.reportsTable > thead > tr > th, .reportsTable > tbody > tr > td {
  border-bottom: 1px solid #c0c0c0;
  text-align: center;
  padding: 10px;
}

/* index.php */
#affiliateCommTbl > thead > tr > th, #affiliateCommTbl > tbody > tr > td {
  border-bottom: 1px solid #c0c0c0;
  text-align: center;
  padding: 10px;
}

#affiliateCode, #affiliateLink {
  font-style: italic;
  font-weight: 700;
}

#affiliateAccuracy, #pageError {
  clear: both;
  text-align: center;
  font-size: 70%;
  padding: 20px;
}

#pageError {
  font-size: 100%;
  font-weight: 700;
}

/* content */
#dashboadSummary {
  max-width: 800px;
  margin: 0 auto;
}

/* earnings.php */
#dateCustom > input {
  width: 100px;
  background-color: #fff;
}

#earnings {
  clear: both;
  margin: 20px 0;
}

.tierblock {
  padding: 10px 0 0 0;
  text-align: center;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
}

.tiertitle {
  margin-top: 10px;
  width: 100%;
  clear: both;
  color: #fff !important;
  background-color: #6c757d;
  color: #000;
}

.tiertitle:hover {
  background-color: #828a91;
}

.lineTier {
  fill: none;
  stroke: steelblue;
  stroke-width: 1px;
}

.dot {
  fill: #c0c0c0;
  stroke: #fff;
}

.dotTier {
  stroke-width: 1px;
}

.tierOff {
  opacity: 0.2;
  fill-opacity: 0.2;
  stroke-width: 1px;
}

.tierOn {
  opacity: 1;
  fill-opacity: 1;
  stroke-width: 2px;
}

div.tooltip {
  position: absolute;
  text-align: center;
  min-width: 60px;
  padding: 5px;
  font: 10px sans-serif;
  color: #fff;
  background: #6c757d;
  border: 1px solid #c0c0c0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  pointer-events: none;
}

#barchart, #linechart {
  width: 800px;
  min-width: 250px;
  height: 300px;
  margin: 20px auto;
  clear: both;
  border: 1px solid #c0c0c0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

#barchart > div, #linechart > div, #affiliateListing > div {
  width: 100px;
  margin: 40px auto;
  color: #b7b5b5;
  font-size: 150%;
}

#linechart > div {
  margin-top: 140px;
}

#barchart {
  height: 100px;
}

#affiliateListing {
  width: auto;
  height: 1000px;
}

/* claims.php */
#claimLimit {
  font-size: 80%;
}

/* affiliates.php */
.node {
  fill: #c0c0c0;
  stroke: #fff;
  stroke-width: 3px;
  cursor: pointer;
}

.nodeMedium {
  stroke-width: 1px;
}

.nodeSmall {
  stroke-width: 0px;
}

.leaf {
  cursor: default;
}

.node:hover {
  fill: #6c757d;
}

.nodeText {
  font-size: 80%;
}

.nodeTextMedium {
  font-size: 40%;
}

.nodeTextSmall {
  font-size: 20%;
}

.nodeTextHighlight {
  font-weight: 700;
  font-style: italic;
}

.nodeDetails {
  fill: #fff;
  font-size: 10px;
}

.sectionText {
  font-size: 500%;
  fill: #c0c0c0;
}

.sectionSep {
  fill: none;
  stroke: #c0c0c0;
  stroke-width: 0.2;
}

.link {
  fill: none;
  stroke: #c0c0c0;
  stroke-width: 1px;
}

/*
mobile
*/
@media (max-width: 575.98px) {
  #affiliateListing {
    height: 300px;
  }

  #barchart, #linechart {
    margin: 20px 0;
    padding: 0;
  }
}