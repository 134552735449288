/*
vars
*/
/*
general
*/
.top-center {
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 100 !important;
}

.fresh-input {
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -ms-border-radius: 9px !important;
  border-radius: 9px !important;
}

.fresh-input-highlight {
  border-color: #f00;
}

.fresh-input-left {
  -webkit-border-radius: 9px 0 0 9px !important;
  -moz-border-radius: 9px 0 0 9px !important;
  -ms-border-radius: 9px 0 0 9px !important;
  border-radius: 9px 0 0 9px !important;
}

.fresh-input-left-highlight {
  border-color: #f00;
}

.fresh-input-right {
  -webkit-border-radius: 0 9px 9px 0 !important;
  -moz-border-radius: 0 9px 9px 0 !important;
  -ms-border-radius: 0 9px 9px 0 !important;
  border-radius: 0 9px 9px 0 !important;
}

.fresh-input-right-highlight {
  border-color: #f00;
}

.fresh-btn {
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -ms-border-radius: 9px !important;
  border-radius: 9px !important;
}

.fresh-btn-hghlight {
  background-color: #f0f0f0;
}

.fresh-dropdown {
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -ms-border-radius: 9px !important;
  border-radius: 9px !important;
}

.fresh-btn-right:hover > .fas {
  animation: bounceRight 2s infinite;
}

.fresh-btn-left:hover > .fas {
  animation: bounceLeft 2s infinite;
}

.btn-tiny {
  font-size: 8px !important;
  background-color: #6c757d;
  color: #fff;
  padding: 1px 4px;
  height: 14px;
}

.btn-tiny:hover {
  color: #fff;
}

#buyTab, #sellTab {
  color: #6c757d;
}

#sectionSuccess {
  border-bottom: 3px solid #28a745;
}

#sectionError {
  border-bottom: 3px solid #dc3545;
}

#buyTab, #sellTab {
  border-bottom: 1px solid #e0e0e0;
}

#buyTab.highlight {
  color: #28a745;
  border-bottom: 3px solid #28a745;
}

#sellTab.highlight {
  color: #dc3545;
  border-bottom: 3px solid #dc3545;
}

.section-clean {
  background-color: #fff;
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -ms-border-radius: 9px !important;
  border-radius: 9px !important;
  -webkit-box-shadow: 0px 0px 50px #e0e0e0 !important;
  -moz-box-shadow: 0px 0px 50px #e0e0e0 !important;
  box-shadow: 0px 0px 50px #e0e0e0 !important;
}

#tabBar {
  cursor: pointer;
  font-size: 200%;
  overflow: hidden;
}

#sellEstimateView img, #buyPairView img {
  width: 16px;
  margin: 0 5px;
}

#buyEstimateView, #sellEstimateView {
  background-color: #f0f0f0;
  border: 1px solid #f0f0f0;
}

#sellTickerView {
  padding: 0 8px;
}

#buyEstimateView div {
  display: inline-block;
}

.estimatemsg {
  color: #c0c0c0;
  font-size: 80%;
  float: right;
}

.savings-info {
  cursor: pointer;
}

.whysave .card:hover {
  transform: scale(1.15);
}

.interest, .capital, .existingoption b {
  font-weight: 700;
  font-size: 120%;
  color: #000 !important;
}

.loansblock {
  background-color: #fff;
  border-width: 3px !important;
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -ms-border-radius: 9px !important;
  border-radius: 9px !important;
  -webkit-box-shadow: 0px 0px 20px #e0e0e0 !important;
  -moz-box-shadow: 0px 0px 20px #e0e0e0 !important;
  box-shadow: 0px 0px 20px #e0e0e0 !important;
}

.loansblocksep {
  border-bottom: 1px solid #e0e0e0;
}

.loansblock .card-header {
  border: 0px;
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -ms-border-radius: 9px !important;
  border-radius: 9px !important;
  background-color: transparent !important;
}

.loansstep {
  position: absolute;
  top: -20px;
  left: 0px;
  width: 120px;
  height: 120px;
  font-size: 800%;
  color: #f0f0f0;
}

.promobox .loansstep {
  top: -30px;
  left: -10px;
}

.loanSlider {
  background: rgb(0, 255, 0);
  background: linear-gradient(141deg, rgb(0, 255, 0) 0%, rgb(255, 240, 0) 50%, rgb(255, 0, 0) 100%);
}

.loanSlider .ui-slider-handle, .loanSlider .ui-state-default {
  background: #0f0 !important;
}

.loanSliderWarning .ui-slider-handle, .loanSliderWarning .ui-state-default {
  background: #ff0 !important;
}

.loanSliderWarningHigh .ui-slider-handle, .loanSliderWarningHigh .ui-state-default {
  background: #ffa500 !important;
}

.loanSliderLiquidated .ui-slider-handle, .loanSliderLiquidated .ui-state-default {
  background: #ff0000 !important;
}

.slideAmount {
  font-weight: 700;
}

.maxBorrow {
  font-size: 40%;
}

.riskWarning {
  float: left;
}

.liquidationWarning {
  float: right;
  display: inline-block;
  color: #f00;
}

.text-muted b {
  font-weight: 700 !important;
}

/*
verification status
*/
.statusText, .statusReview, .statusWaiting, .statusSuccess {
  font-size: 200%;
}

.statusIcon {
  font-size: 1000%;
  color: #c0c0c0;
}

.statusText {
  font-size: 100%;
  top: 55px;
  right: 20px;
}

/*
gradient animation
*/
:root {
  --gradient-warning: linear-gradient(45deg, #f00, #c00, #f00);
  --gradient-promo: linear-gradient(45deg, #f8f9fa, #dfe0e1, #f8f9fa);
}

@keyframes bg-animation {
  0% {
    background-position: left;
  }
  50% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}
.backgroundwarning {
  color: #fff;
  background-image: var(--gradient-warning);
  background-size: 500%;
  animation: bg-animation 25s infinite;
}

.backgroundpromo {
  color: #fff;
  background-image: var(--gradient-promo);
  background-size: 500%;
  animation: bg-animation 25s infinite;
}

/*
bounce animations
*/
@keyframes bounceLeft {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(5px);
    transform: translateX(5px);
  }
  60% {
    -ms-transform: translateX(2px);
    transform: translateX(2px);
  }
}
@keyframes bounceRight {
  0%, 20%, 50%, 80%, 100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  60% {
    -ms-transform: translateX(-2px);
    transform: translateX(-2px);
  }
}
/*
mobile
*/
@media (max-width: 575.98px) {
  #tabBar {
    font-size: 160%;
  }
}