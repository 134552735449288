/*
vars
*/
/*
original
*/
html, body {
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body.swal2-height-auto {
  height: 100% !important;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1em;
}

#container {
  min-height: 100%;
  position: relative;
}

#content {
  min-height: 100%;
  padding-bottom: 120px;
}

.promo, .promoboxtxt {
  font-size: 1.6em;
  font-weight: 200;
}

.promo2 {
  font-size: 1.2em;
  font-weight: 200;
}

.promobox:hover {
  transform: scale(1.15);
  -webkit-box-shadow: 0px 0px 10px #e0e0e0;
  -moz-box-shadow: 0px 0px 10px #e0e0e0;
  box-shadow: 0px 0px 10px #e0e0e0;
}

.promobox .loansstep {
  top: -5px;
  left: 10px;
  padding-left: 18px;
  text-size: 250%;
}

.card {
  overflow: hidden;
}

.vertical-center {
  position: relative;
  top: 50%;
  margin: 100px auto 50px auto;
}

.page-title, .page-title-clean {
  font-size: 30px;
  letter-spacing: -1px;
  display: block;
  color: #48535D;
  font-weight: 300;
}

.page-title small {
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 300;
}

/*
viewport detection
*/
body::before {
  display: none;
  content: "xs";
}

@media (min-width: 576px) {
  body::before {
    content: "sm";
  }
}
@media (min-width: 768px) {
  body::before {
    content: "md";
  }
}
@media (min-width: 992px) {
  body::before {
    content: "lg";
  }
}
@media (min-width: 1200px) {
  body::before {
    content: "xl";
  }
}
/*
misc
*/
a {
  outline: 0;
}

h1.page-title {
  width: 100%;
  padding: 5px;
  border-bottom: 1px solid #c0c0c0;
}

h2 {
  font-size: 150%;
  padding: 5px;
}

h3 {
  font-size: 120%;
  padding: 5px;
}

.disabled {
  background-color: #c0c0c0;
  color: #cfcfcf;
  cursor: not-allowed;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.swal-button--confirm, .swal-button--confirm:hover {
  background-color: #6c757d;
}

.fa-exclamation-circle {
  color: #f00;
}

.fa-check-circle {
  color: green;
}

.inline-error {
  background-color: #f00;
  color: #fff;
}

.inline-success {
  background-color: #090;
  color: #fff;
}

.input-status {
  font-size: 120%;
}

.alert, .alert:hover {
  color: #000;
}

.alert .fa-check-circle, .alert .fa-exclamation-circle {
  color: #000;
}

.input-group-text {
  background-color: #fff;
}

.input-group .form-control {
  margin: 0px !important;
}

.form-control:focus {
  border-color: #c0c0c0;
  box-shadow: 0 0 0 0 #c0c0c0;
  -moz-box-shadow: 0 0 0 0 #c0c0c0;
  -webkit-box-shadow: 0 0 0 0 #c0c0c0;
}

.btn {
  border-radius: 0.25em;
}

.form-group a {
  color: #6c757d;
}

.form-group div small {
  display: block;
}

.dropdown a img {
  width: 16px;
  margin-right: 10px;
}

.tableicon {
  width: 1.5em;
  border-radius: 50%;
  margin-right: 15px;
  align-content: flex-start;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.errorHeader {
  font-size: 200%;
  padding: 20px;
}

.errorBlock {
  font-family: "Open Sans", sans-serif;
  letter-spacing: -1px;
  color: #48535D;
  font-weight: 300;
  font-size: 150%;
  text-align: center;
  padding: 20px;
}

.custom-dropdown {
  display: inline-block;
}

.custom-dropdown .dropdown-toggle, .custom-dropdown .dropdown-menu li {
  padding: 5px 5px;
}

.custom-dropdown .dropdown-menu {
  width: 320px;
  max-height: 300px;
  overflow-y: scroll;
}

.custom-dropdown .dropdown-menu li:hover {
  cursor: pointer;
  font-weight: 700;
}

.custom-dropdown .dropdown-menu li img, .custom-dropdown .btn img {
  margin: 0 5px;
  width: 16px;
}

.dropdown-menu li i {
  float: right;
  color: #f00;
}

#loginform .input-group input {
  border-right: 1px solid #fff;
}

.input-group-prepend {
  color: #000;
  border-left: 1px solid #fff;
}

.input-group-append {
  border-right: 1px solid #fff;
}

.input-group-prepend img, .input-group-append img {
  width: 18px;
}

/*
loading
*/
#reportLoading {
  position: absolute;
  top: 50%;
  left: calc(50% - 25px);
  display: none;
  margin: 20px auto;
  text-align: center;
}

.lds-circle {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: 6px;
  border-radius: 50%;
  background-image: url("/images/loading-icn.png");
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
/*
site structure
*/
header, .modal-header {
  width: 100%;
  background: #242B32;
}

.grecaptcha-badge {
  display: none !important;
}

.navbar {
  background-image: url("/images/rads-rays-big.png");
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:active {
  color: #fff;
}

#user-img {
  font-size: 100%;
  text-align: center;
  margin: 0 8px;
}

.dropdown-header {
  font-size: 80%;
  font-style: italic;
  color: #fff;
  text-align: center;
}

#balance {
  background-color: #48535D;
  width: 100%;
  padding: 5px;
  text-align: right;
  color: #fff;
  z-index: 1;
}

footer {
  position: absolute;
  bottom: 0;
  background: #242B32 url("/images/rads-rays-big.png");
  width: 100%;
  color: #fff;
  padding: 10px 0 0 0;
  margin-top: 10px;
  font-size: 100%;
}

footer a, footer a:hover {
  color: #fff;
}

footer ul {
  list-style-type: none;
  margin-left: 10px;
}

footer h3 {
  font-size: 120%;
}

#copyright {
  width: 100%;
  font-size: 80%;
  padding: 5px;
  margin-top: 10px;
  background-color: #48535D;
}

.modal-header .close {
  color: #fff;
}

.fixed-header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: white;
}

.fixed-header-table {
  height: 600px;
}

/*
social
*/
.socialShare {
  line-height: 24px;
}

.socialFollow {
  padding: 6px;
  font-size: 14px !important;
  width: 25px;
  height: 25px;
  text-align: center;
  text-decoration: none !important;
  margin-right: 10px;
  margin-top: 5px;
}

.socialFollow:hover {
  opacity: 0.7;
}

.google {
  background: #DC4637;
  color: white;
}

.whatsapp {
  background: #34af23;
  color: white;
}

.email {
  background: #86aace;
  color: white;
}

.twitter {
  color: white;
}

.facebook {
  background: #3B5998;
  color: white;
}

.youtube {
  background: #ff0000;
  color: white;
}

.instagram {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  color: white;
}

/*
members
*/
#balanceChart {
  width: 600px;
  height: 600px;
  margin: 0 auto;
}

/*
mobile
*/
@media (max-width: 575.98px) {
  body {
    margin-bottom: 0;
  }

  footer {
    position: static;
    height: auto;
  }

  #content {
    padding-bottom: 10px;
  }

  .vertical-center {
    position: static;
    margin: 10px auto;
  }

  #loginform input, loginform button {
    margin-bottom: 5px;
  }

  #balanceChart {
    width: 300px;
  }

  .custom-dropdown .dropdown-menu {
    width: 100%;
    min-width: 200px;
    max-width: 300px;
  }

  .btn-mobile {
    width: 100% !important;
  }
}